import React from 'react'

const SVGDefs = () => {
  return (
    <svg width={0} height={0} style={{ position: 'absolute' }}>
      <defs>
        <linearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0" stopColor="#225599ff" />
          <stop offset="1" stopColor="#22559900" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SVGDefs
