let subscription

function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window
}

async function askForPushNotificationPermission() {
  return await Notification.requestPermission()
}

async function createPushNotificationSubscription(vapidPublicKey) {
  const serviceWorker = await navigator.serviceWorker.ready

  subscription = await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: vapidPublicKey,
  })
  return subscription
}

async function destroyPushNotificationSubscription() {
  await subscription.unsubscribe()
}

async function receivePushNotificationSubscription() {
  if (subscription) {
    return subscription
  }

  const serviceWorker = await navigator.serviceWorker.ready

  subscription = await serviceWorker.pushManager.getSubscription()
  return subscription
}

export {
  isPushNotificationSupported,
  askForPushNotificationPermission,
  createPushNotificationSubscription,
  destroyPushNotificationSubscription,
  receivePushNotificationSubscription,
}
