const _toRealDate = (data) => {
  const newData = {}
  Object.entries(data).forEach(([unit, datas]) => {
    newData[unit] = datas.map((d) => ({ date: new Date(d.date), value: d.value }))
  })
  return newData
}

const _addGapNullValues = (data, density) => {
  const newData = {}
  let tickWidth
  switch (density) {
    case 'ONE_MINUTE':
      tickWidth = 60 * 1000
      break
    case 'TEN_MINUTES':
      tickWidth = 10 * 60 * 1000
      break
    case 'ONE_HOUR':
      tickWidth = 60 * 60 * 1000
      break
    case 'ONE_DAY':
      tickWidth = 24 * 60 * 60 * 1000
      break
    case 'ONE_MONTH':
      tickWidth = 30 * 24 * 60 * 60 * 1000
      break
    case 'ONE_YEAR':
      tickWidth = 365 * 24 * 60 * 60 * 1000
      break
    default:
      console.error('missing case', density)
  }

  Object.entries(data).forEach(([unit, datas]) => {
    newData[unit] = []
    datas.reduce((prev, current, index) => {
      if (prev && current.date.getTime() - prev.date.getTime() >= 2 * tickWidth) {
        const gapDate = new Date(prev.date.getTime())
        switch (density) {
          case 'ONE_MINUTE':
            gapDate.setUTCMinutes(gapDate.getUTCMinutes() + 1)
            break
          case 'TEN_MINUTES':
            gapDate.setUTCMinutes(gapDate.getUTCMinutes() + 10)
            break
          case 'ONE_HOUR':
            gapDate.setUTCHours(gapDate.getUTCHours() + 1)
            break
          case 'ONE_DAY':
            gapDate.setUTCDate(gapDate.getUTCDate() + 1)
            break
          case 'ONE_MONTH':
            gapDate.setUTCMonth(gapDate.getUTCMonth() + 1)
            gapDate.setUTCHours(0)
            break
          case 'ONE_YEAR':
            gapDate.setUTCFullYear(gapDate.getUTCFullYear() + 1)
            break
          default:
            console.error('missing case', density)
        }
        newData[unit].push({ date: gapDate, value: null })
      }
      newData[unit].push(current)
      return current
    }, null)
  })

  return newData
}

export { _toRealDate, _addGapNullValues }
