import React, { createContext, useReducer } from 'react'

const initialState = {
  isAuthenticated: false,
  config: null,
  paramRooms: null,
  paramRange: null,
  paramDensity: null,
  isWebPushEnabled: null,
}

const store = createContext(initialState)
const { Provider } = store

const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, statePatch) => {
    return { ...state, ...statePatch }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, AppStateProvider }
