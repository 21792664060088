import { useContext } from 'react'

import { store } from '../AppState'
import useApi from './useApi'

const useAuth = () => {
  const { dispatch } = useContext(store)
  const { postAuth, deleteAuth } = useApi()

  const login = async (password) => {
    const res = await postAuth(password)

    if (res.ok) {
      dispatch({ isAuthenticated: true })
      return true
    }
    return false
  }

  const logout = async () => {
    await deleteAuth()

    dispatch({ isAuthenticated: false })
  }

  return { login, logout }
}

export default useAuth
