import { _toRealDate, _addGapNullValues } from '../utils/telemetry'

const headers = { Accept: 'application/json', 'Content-Type': 'application/json' }
const _get = async (path) => await fetch(path, { method: 'GET', headers })
const _delete = async (path) => await fetch(path, { method: 'DELETE', headers })
const _post = async (path, body) => await fetch(path, { method: 'POST', headers, body: JSON.stringify(body) })

const _objectToQueryParamString = (object) => {
  if (!object) {
    return ''
  }

  const queryParamString = Object.keys(object)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(object[key]))
    .join('&')

  if (queryParamString.length) {
    return '?' + queryParamString
  }
  return ''
}

const useApi = () => {
  const postAuth = async (password) => {
    return await _post('/api/auth', { password })
  }

  const deleteAuth = async () => {
    return await _delete('/api/auth')
  }

  const getConfig = async () => {
    const res = await _get('/api/config')
    return await res.json()
  }

  const getSensorData = async (params) => {
    const res = await _get(`/api/telemetry${_objectToQueryParamString(params)}`)
    let data = await res.json()

    data = _toRealDate(data)
    data = _addGapNullValues(data, params.density)

    return data
  }

  const getCurrentSensorData = async (params) => {
    const res = await _get(`/api/telemetry/current${_objectToQueryParamString(params)}`)
    return await res.json()
  }

  const postPushNotificationSubscription = async (subscription) => {
    return await _post('/api/web-push-sub', subscription)
  }

  const getPushNotificationSubscription = async (subscription) => {
    const base64Endpoint = new Buffer(subscription.endpoint).toString('base64')
    return await _get(`/api/web-push-sub/${base64Endpoint}`)
  }

  const deletePushNotificationSubscription = async (subscription) => {
    const base64Endpoint = new Buffer(subscription.endpoint).toString('base64')
    return await _delete(`/api/web-push-sub/${base64Endpoint}`)
  }

  return {
    postAuth,
    deleteAuth,
    getConfig,
    getSensorData,
    getCurrentSensorData,
    postPushNotificationSubscription,
    getPushNotificationSubscription,
    deletePushNotificationSubscription,
  }
}

export default useApi
