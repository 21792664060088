import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

import styles from './styles.module.scss'

const Login = ({ history }) => {
  const [password, setPassword] = useState()
  const { login } = useAuth()

  const changePassword = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const isAuthenticated = await login(password)

    if (isAuthenticated) {
      history.push('/')
    }
  }

  return (
    <form className={styles.container} onSubmit={(event) => handleSubmit(event)}>
      <input
        className={styles.input}
        autoFocus
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        type="password"
        name="password"
        onChange={changePassword}
      />
      <button className={styles.button} type="submit">
        Weiter
      </button>
    </form>
  )
}

export default withRouter(Login)
