import React, { useEffect, useContext, useState } from 'react'
import { withRouter, Route } from 'react-router-dom'

import { store } from '../AppState'
import useAuth from '../hooks/useAuth'
import useApi from '../hooks/useApi'

const PrivateRoute = ({ loginPath, children, history, ...rest }) => {
  const { state, dispatch } = useContext(store)
  const [isChecking, setIsChecking] = useState(false)
  const { login } = useAuth()
  const { getConfig } = useApi()

  useEffect(() => {
    const main = async () => {
      if (state.isAuthenticated && !state.config) {
        const config = await getConfig()
        dispatch({ config })
      }
    }
    main()
  }, [state, getConfig, dispatch])

  useEffect(() => {
    const main = async () => {
      if (!isChecking && !state.isAuthenticated) {
        setIsChecking(true)
        const isAuthenticated = await login()

        if (!isAuthenticated) {
          history.push(loginPath)
        }
      }
    }
    main()
  }, [history, state.isAuthenticated, login, isChecking, loginPath])

  const render = () => {
    if (state.isAuthenticated && state.config) {
      return children
    }
  }

  return <Route history {...rest} render={render} />
}

export default withRouter(PrivateRoute)
