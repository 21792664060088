import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as SVGLogo } from '../assets/logo.svg'
import { ReactComponent as SVGBellOff } from '../assets/material-design-icons/bell-off.svg'
import { ReactComponent as SVGBellRing } from '../assets/material-design-icons/bell-ring.svg'
import Rooms from '../utils/rooms'
import Ranges from '../utils/ranges'
import Densities from '../utils/densities'

import styles from './BottomBar.module.scss'
import BottomBarSelect from './BottomBarSelect'
import { store } from '../AppState'
import usePushNotifications from '../hooks/usePushNotifications'

const BottomBar = () => {
  const { state, dispatch } = useContext(store)
  const [params, setParams] = useState({})
  const { enablePushNotifications, disablePushNotifications } = usePushNotifications()

  const toggleWebPush = () => {
    if (state.isWebPushEnabled) {
      disablePushNotifications()
    } else {
      enablePushNotifications()
    }
  }

  useEffect(() => {
    let { paramRooms, paramRange, paramDensity } = params
    if (paramRooms && paramRange && paramDensity) {
      const allowedDensities = Ranges.getAllowedDensities(paramRange)

      if (!allowedDensities.includes(paramDensity)) {
        dispatch({ ...params, paramDensity: allowedDensities[allowedDensities.length - 1] })
        return
      }

      dispatch(params)
    }
    // eslint-disable-next-line
  }, [params])

  const onChangeHandler = (storageKey, value) => {
    setParams((prev) => ({ ...prev, [storageKey]: value }))
  }

  return (
    <div className={styles.container}>
      <Link className={styles.link} to="/">
        <SVGLogo className={styles.logo} />
      </Link>
      <hr className={styles.divider} />
      <BottomBarSelect
        label="Rooms"
        storageKey="paramRooms"
        options={Rooms.getEntries()}
        defaultValue={Rooms.getDefault()}
        type="checkbox"
        onChange={onChangeHandler}
      />
      <hr className={styles.divider} />
      <BottomBarSelect
        label="Range"
        storageKey="paramRange"
        options={Ranges.getEntries()}
        defaultValue={Ranges.getDefault()}
        type="radio"
        onChange={onChangeHandler}
      />
      <hr className={styles.divider} />
      <BottomBarSelect
        label="Density"
        storageKey="paramDensity"
        options={Densities.getEntries()}
        defaultValue={Densities.getDefault()}
        type="radio"
        onChange={onChangeHandler}
        enabledOptions={Ranges.getAllowedDensities(params.paramRange)}
      />
      <hr className={styles.divider} />
      <div className={styles.webPushToggle} onClick={toggleWebPush}>
        {!state.isWebPushEnabled && <SVGBellOff className={styles.webPushToggleButtonOff} />}
        {state.isWebPushEnabled && <SVGBellRing className={styles.webPushToggleButtonOn} />}
      </div>
    </div>
  )
}

export default BottomBar
