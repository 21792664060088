const _tickFormatHelper = (formatFn) => {
  return (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    return formatFn(date)
  }
}

const config = {
  ONE_MINUTE: {
    label: '1 minute',
    tickFormat: _tickFormatHelper(
      (date) => `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`
    ),
  },
  TEN_MINUTES: {
    label: '10 minutes',
    tickFormat: _tickFormatHelper(
      (date) => `${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}`
    ),
  },
  ONE_HOUR: {
    label: '1 hour',
    tickFormat: _tickFormatHelper((date) => `${date.getUTCHours().toString().padStart(2, '0')}:00`),
  },
  ONE_DAY: {
    label: '1 day',
    tickFormat: _tickFormatHelper(
      (date) => `${date.getUTCDate().toString().padStart(2, '0')}.${date.getUTCMonth().toString().padStart(2, '0')}.`
    ),
  },
  ONE_MONTH: {
    label: '1 month',
    tickFormat: _tickFormatHelper((date) => `${(date.getUTCMonth() + 1).toString().padStart(2, '0')}.`),
  },
  ONE_YEAR: {
    label: '1 year',
    tickFormat: _tickFormatHelper((date) => date.getUTCFullYear().toString()),
  },
}

const getEntries = () => {
  return Object.entries(config).map(([key, value]) => ({ key, ...value }))
}

const getDefault = () => {
  return 'ONE_HOUR'
}

const getTickFormat = (key) => {
  return config[key].tickFormat
}

export default {
  getEntries,
  getDefault,
  getTickFormat,
}
