const config = {
  LIVING_ROOM: {
    label: 'Living room',
    color: '#08ff00',
  },
  BEDROOM: {
    label: 'Bedroom',
    color: '#ff8400',
  },
}

const getEntries = () => {
  return Object.entries(config).map(([key, value]) => ({ key, ...value }))
}

const getDefault = () => {
  return ['LIVING_ROOM']
}

const getColor = (key) => {
  return config[key].color
}

export default {
  getEntries,
  getDefault,
  getColor,
}
