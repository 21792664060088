const _tickFormatHelper = (formatFn) => {
  return (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date)
    }
    return formatFn(date)
  }
}

const config = {
  LAST_24H: {
    label: '24 hours',
    rangeDays: 1,
    zoomRange: {
      hours: -2,
      days: 0,
      months: 0,
    },
    allowedDensities: ['ONE_HOUR', 'TEN_MINUTES', 'ONE_MINUTE'],
    tickFormat: _tickFormatHelper((date) => `${date.getUTCHours().toString().padStart(2, '0')}:00`),
  },
  LAST_7D: {
    label: '7 days',
    rangeDays: 7,
    zoomRange: {
      hours: 0,
      days: -2,
      months: 0,
    },
    allowedDensities: ['ONE_DAY', 'ONE_HOUR', 'TEN_MINUTES'],
    tickFormat: _tickFormatHelper((date) => `${date.getUTCDate().toString().padStart(2, '0')}.`),
  },
  LAST_30D: {
    label: '30 days',
    rangeDays: 30,
    zoomRange: {
      hours: 0,
      days: -4,
      months: 0,
    },
    allowedDensities: ['ONE_DAY', 'ONE_HOUR'],
    tickFormat: _tickFormatHelper((date) => `${date.getUTCDate().toString().padStart(2, '0')}.`),
  },
  LAST_1Y: {
    label: '1 year',
    rangeDays: 365,
    zoomRange: {
      hours: 0,
      days: 0,
      months: -1,
    },
    allowedDensities: ['ONE_MONTH', 'ONE_DAY'],
    tickFormat: _tickFormatHelper((date) => date.getUTCFullYear().toString()),
  },
}

const getEntries = () => {
  return Object.entries(config).map(([key, value]) => ({ key, ...value }))
}

const getDefault = () => {
  return 'LAST_24H'
}

const calculateFromTo = (key) => {
  const from = new Date()
  const to = new Date()

  from.setDate(from.getDate() - config[key].rangeDays)
  return { from: from.toISOString(), to: to.toISOString() }
}

const getZoomRange = (key) => {
  return config[key].zoomRange
}

const getAllowedDensities = (key) => {
  if (!Object.keys(config).includes(key)) {
    return []
  }
  return config[key].allowedDensities
}

const getTickFormat = (key) => {
  return config[key].tickFormat
}

export default {
  getEntries,
  getDefault,
  calculateFromTo,
  getZoomRange,
  getAllowedDensities,
  getTickFormat,
}
