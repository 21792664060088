import React, { useContext, useEffect, useState } from 'react'

import { store } from '../AppState'

import styles from './BottomBarSelect.module.scss'

const BottomBarSelect = ({ label, storageKey, options, defaultValue, type, onChange, enabledOptions }) => {
  const { state } = useContext(store)
  const [showSelect, setShowSelect] = useState(false)

  useEffect(() => {
    const storageItem = localStorage.getItem(storageKey)
    if (storageItem) {
      if (type === 'checkbox') {
        onChange(storageKey, JSON.parse(storageItem))
      } else {
        onChange(storageKey, storageItem)
      }
    } else {
      onChange(storageKey, defaultValue)
      if (type === 'checkbox') {
        localStorage.setItem(storageKey, JSON.stringify(defaultValue))
      } else {
        localStorage.setItem(storageKey, defaultValue)
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleClickSelect = () => {
    setShowSelect((prev) => !prev)
  }

  const handleClickOption = (type, key) => {
    if (type === 'checkbox') {
      let newValues
      if (state[storageKey].includes(key)) {
        newValues = [...state[storageKey]]
        newValues.splice(newValues.indexOf(key), 1)
      } else {
        newValues = [...state[storageKey], key]
      }

      if (!newValues.length) {
        return
      }

      onChange(storageKey, newValues)
      localStorage.setItem(storageKey, JSON.stringify(newValues))
    } else {
      onChange(storageKey, key)
      localStorage.setItem(storageKey, key)
    }

    handleClickSelect()
  }

  return (
    <div className={styles.select}>
      <button className={styles.selectButton} onClick={handleClickSelect}>
        {label}
      </button>
      {showSelect && (
        <div className={styles.selectOptions}>
          {options.map((option) => (
            <label key={option.key} className={styles.selectOption}>
              <input
                type={type}
                checked={state[storageKey].includes(option.key)}
                disabled={enabledOptions && enabledOptions.length && !enabledOptions.includes(option.key)}
                onChange={() => handleClickOption(type, option.key)}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  )
}

export default BottomBarSelect
