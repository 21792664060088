import React, { Suspense, lazy, useContext } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Login from './pages/Login'
import Logout from './pages/Logout'
import PrivateRoute from './components/PrivateRoute'

import styles from './AppRouter.module.scss'
import BottomBar from './components/BottomBar'
import { store } from './AppState'

const Home = lazy(() => import('./pages/Home'))
const Detail = lazy(() => import('./pages/Detail'))

const AppRouter = () => {
  const { state } = useContext(store)

  return (
    <BrowserRouter>
      <div className={styles.container}>
        <div className={styles.content}>
          <Suspense fallback={null}>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>

              <Route path="/logout">
                <Logout />
              </Route>

              <PrivateRoute path="/detail/:unit" loginPath="/login">
                <Detail />
              </PrivateRoute>

              <PrivateRoute path="/" loginPath="/login">
                <Home />
              </PrivateRoute>
            </Switch>
          </Suspense>
        </div>
        {state.isAuthenticated && <BottomBar />}
      </div>
    </BrowserRouter>
  )
}

export default AppRouter
