import React from 'react'

import { AppStateProvider } from './AppState'
import AppRouter from './AppRouter'
import SVGDefs from './components/SVGDefs'

const App = () => {
  return (
    <AppStateProvider>
      <AppRouter />
      <SVGDefs />
    </AppStateProvider>
  )
}

export default App
