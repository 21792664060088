import { useEffect, useContext } from 'react'
import useApi from './useApi'

import {
  isPushNotificationSupported,
  receivePushNotificationSubscription,
  askForPushNotificationPermission,
  createPushNotificationSubscription,
  destroyPushNotificationSubscription,
} from '../utils/push-notifications'
import { store } from '../AppState'

const pushNotificationSupported = isPushNotificationSupported()

export default function usePushNotifications() {
  const { state, dispatch } = useContext(store)
  const {
    postPushNotificationSubscription,
    getPushNotificationSubscription,
    deletePushNotificationSubscription,
  } = useApi()

  useEffect(() => {
    const main = async () => {
      if (pushNotificationSupported) {
        const sub = await receivePushNotificationSubscription()
        if (sub) {
          const res = await getPushNotificationSubscription(sub)
          if (res.status === 200) {
            dispatch({ isWebPushEnabled: true })
          }
        }
      }
    }
    main()
    // eslint-disable-next-line
  }, [])

  const enablePushNotifications = async () => {
    const consent = await askForPushNotificationPermission()

    if (consent !== 'granted') {
      console.warn('permission denied')
      dispatch({ isWebPushEnabled: false })
      return
    }

    await createPushNotificationSubscription(state.config.vapidPublicKey)
    await postPushNotificationSubscription(await receivePushNotificationSubscription())

    dispatch({ isWebPushEnabled: true })
  }

  const disablePushNotifications = async () => {
    await deletePushNotificationSubscription(await receivePushNotificationSubscription())
    await destroyPushNotificationSubscription()

    dispatch({ isWebPushEnabled: false })
  }

  return {
    enablePushNotifications,
    disablePushNotifications,
  }
}
